import React, { useState, useEffect } from "react";
import topRightArrow from "../../../static/assets/images/top-right-red-arrow.svg"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Modal = ({ isOpen, onClose, children }) => {

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div 
      className="modal-overlay" 
      onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "9999999999",
      }}
    >
      <div 
        className="modal-content" 
        onClick={(e) => e.stopPropagation()}
        style={{
          background: "#fff",
          padding: "20px",
          borderRadius: "4px",
          position: "relative",
          padding: "80px",
          maxHeight: "90vh",
          overflowY: "auto"
        }}
      >
        <div>
          <div>
            <button 
              onClick={onClose} 
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                background: "transparent",
                border: "none",
                fontSize: "16px",
                cursor: "pointer"
              }}
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Vector">
              <path d="M13.1277 6.5L10.0407 9.587L6.9535 6.5L6.5 6.954L9.58725 10.0407L6.5 13.1277L6.9535 13.5815L10.0407 10.4945L13.1277 13.5815L13.5815 13.1277L10.4945 10.0407L13.5815 6.954L13.1277 6.5Z" fill="#FF0245"/>
              <path d="M10 0C4.486 0 0 4.486 0 10C0 15.5142 4.486 20 10 20C15.5142 20 20 15.5142 20 10C20 4.486 15.5142 0 10 0ZM10 19.2857C4.87975 19.2857 0.71425 15.12 0.71425 10C0.71425 4.87975 4.87975 0.71425 10 0.71425C15.12 0.71425 19.2857 4.87975 19.2857 10C19.2857 15.12 15.12 19.2857 10 19.2857Z" fill="#FF0245"/>
              </g>
              </svg>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

const Team = ({data}) => {
  const [modalOpen, setModalOpen] = useState(false);

  // Add or remove the "modalOpen" class on the body element
  useEffect(() => {
    if (modalOpen) {
      document.body.classList.add("modalIsOpen");
    } else {
      document.body.classList.remove("modalIsOpen");
    }

    // Cleanup function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove("modalIsOpen");
    };
  }, [modalOpen]);

  const openModal = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

const richTextDoc = JSON.parse(data.teamRichText.raw);

let headingBlock = null;
let paragraphs = [];

for (let i = 0; i < richTextDoc.content.length; i++) {
  const block = richTextDoc.content[i];

  if (!headingBlock && block.nodeType === BLOCKS.HEADING_2) {
    headingBlock = block;
    continue; 
  }

  if (headingBlock && block.nodeType === BLOCKS.PARAGRAPH) {
      paragraphs.push(block);
      if (paragraphs.length === 2) {
        break; // we got two paragraphs
      }
    }
  }

  const truncatedContent = [];
  if (headingBlock) truncatedContent.push(headingBlock);
  truncatedContent.push(...paragraphs);

  const truncatedDoc = {
    ...richTextDoc,
    content: truncatedContent
  };

  const truncatedRichTextField = {
    raw: JSON.stringify(truncatedDoc),
    references: data.teamRichText.references || []
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 onClick={openModal} style={{ cursor: "pointer"}}>
          {children}
        </h2>
      ),
    },
  };


  return (
    <section className="about-team">
      <div className="container">
        <h2 className="about-title">{data.teamTitle}</h2>
        <div className="team-wrapper">
          <div className="dr-img">
            <img src={data.founderImg.file.url}
              alt="Dr. Ajaib S. Dhaliwal"
              onClick={openModal}
              style={{ cursor: 'pointer'}} />
          </div>
          <div className="team-content-wrapper">
            <span>
              {data.topTitle}
            </span>
            {renderRichText(truncatedRichTextField, options)}

            <a href="#" onClick={openModal}>
              Read More
              <img src={topRightArrow} width="10" height="9"/>
            </a>
          </div>
        </div>

        <Modal isOpen={modalOpen} onClose={closeModal}>
          <div>
              <img 
                src="http://images.ctfassets.net/c6ntbd8mohr0/bhvgzVqGsJmizmXSnzDdp/bfc42b4dc3c8808016dafb638d715895/Dr-D.jpg" 
                alt="Dr. Ajaib S. Dhaliwal" 
              />
              <span>
                {data.topTitle}
              </span>
              {renderRichText(data.teamRichText)}
          </div> 
        </Modal>
      </div>
    </section>
  );
};

export default Team;